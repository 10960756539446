import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../studentModals";
import StudentSidebar from "./studentSidebar";
import StudentBreadcrumb from "./studentBreadcrumb";
import { useApi } from "../../../../utils/api";
import {
  setStudentError,
  setStudentLoading,
  setStudentSuccess,
} from "../../../../core/data/redux/studentSlice";

const StudentDetails = () => {
  const routes = all_routes;
  const location = useLocation();
  const dispatch = useDispatch();
  const { studentId } = location.state || {};
  const { AxiosGetWithToken } = useApi();

  // Get student data from Redux store
  const { currentStudent: student, loading: isLoading } = useSelector(
    (state: any) => state.studentSlice
  );

  useEffect(() => {
    fetchStudent();
  }, [studentId]); // Added studentId as dependency

  const fetchStudent = async () => {
    try {
      dispatch(setStudentLoading());
      const response = await AxiosGetWithToken(`/student/${studentId}`);

      if (response && response.student) {
        dispatch(setStudentSuccess(response.student));
      }
    } catch (error) {
      console.error("Failed to fetch student:", error);
      dispatch(setStudentError("Failed to fetch student details"));
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <StudentBreadcrumb studentId={studentId} />
          </div>

          <div className="row">
            {student && <StudentSidebar student={student} />}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link
                        to={routes.studentDetail}
                        className="nav-link active"
                      >
                        <i className="ti ti-school me-2" />
                        Student Details
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentFees} className="nav-link">
                        <i className="ti ti-report-money me-2" />
                        Fees
                      </Link>
                    </li>
                  </ul>

                  {/* Parents Information */}
                  <div className="card">
                    <div className="card-header">
                      <h5>Parents Information</h5>
                    </div>
                    <div className="card-body">
                      <div className="border rounded p-3 pb-0 mb-3">
                        <div className="row">
                          <div className="col-sm-6 col-lg-4">
                            <div className="d-flex align-items-center mb-3">
                              <div className="ms-2 overflow-hidden">
                                <h5 className="text-truncate">
                                  {student?.father.name}
                                </h5>
                                <p className="text-primary">Father</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">Phone</p>
                              <p>{student?.father.mobileNumber}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mb-3 overflow-hidden me-3">
                                <p className="text-dark fw-medium mb-1">
                                  Occupation
                                </p>
                                <p className="text-truncate">
                                  {student?.father.occupation}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border rounded p-3 pb-0 mb-3">
                        <div className="row">
                          <div className="col-sm-6 col-lg-4">
                            <div className="d-flex align-items-center mb-3">
                              <div className="ms-2 overflow-hidden">
                                <h5 className="text-truncate">
                                  {student?.mother.name}
                                </h5>
                                <p className="text-primary">Mother</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">Phone</p>
                              <p>{student?.mother.mobileNumber}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mb-3 overflow-hidden me-3">
                                <p className="text-dark fw-medium mb-1">
                                  Occupation
                                </p>
                                <p className="text-truncate">
                                  {student?.mother.occupation}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {student?.hasGuardian && (
                        <div className="border rounded p-3 pb-0 mb-3">
                          <div className="row">
                            <div className="col-sm-6 col-lg-4">
                              <div className="d-flex align-items-center mb-3">
                                <div className="ms-2 overflow-hidden">
                                  <h5 className="text-truncate">
                                    {student?.guardian?.name}
                                  </h5>
                                  <p className="text-primary">{`Guardian: ${student.guardian?.relation}`}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                              <div className="mb-3">
                                <p className="text-dark fw-medium mb-1">
                                  Phone
                                </p>
                                <p>{student?.guardian?.mobileNumber}</p>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="mb-3 overflow-hidden me-3">
                                  <p className="text-dark fw-medium mb-1">
                                    Occupation
                                  </p>
                                  <p className="text-truncate">
                                    {student?.guardian?.occupation}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Address */}
                  <div className="col-xxl-12 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header">
                        <h5>Address</h5>
                      </div>
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                          <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                            <i className="ti ti-map-pin-up" />
                          </span>
                          <div>
                            <p className="text-dark fw-medium mb-1">
                              Current Address
                            </p>
                            <p>{student?.address}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Previous School Details */}
                  <div className="col-xxl-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>Previous School Details</h5>
                      </div>
                      <div className="card-body pb-1">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Previous School Name
                              </p>
                              <p>{student?.previousSchool.name}</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Period of Study
                              </p>
                              <p>{student?.previousSchool.periodOfStudy}</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">Class</p>
                              <p>{student?.previousSchool.class}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Medical History */}
                  <div className="col-xxl-12 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header">
                        <h5>Medical History & Identification Marks</h5>
                      </div>
                      <div className="card-body pb-1">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Known Allergies
                              </p>
                              {student?.allergies.map(
                                (allergy: any, index: any) => (
                                  <span
                                    key={index}
                                    className="badge bg-light text-dark"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {allergy}
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Medications
                              </p>
                              {student?.medications.map(
                                (medication: any, index: any) => (
                                  <span
                                    key={index}
                                    className="badge bg-light text-dark"
                                    style={{ marginRight: "5px" }}
                                  >
                                    {medication}
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Identification Marks
                              </p>
                              <p>{student?.identificationMarks}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StudentModals />
    </>
  );
};

export default StudentDetails;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StudentData } from "../interface";

interface StudentState {
  currentStudent: StudentData | null;
  loading: boolean;
  error: string | null;
}

const initialState: StudentState = {
  currentStudent: null,
  loading: false,
  error: null,
};

const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setStudentLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setStudentSuccess: (state, action: PayloadAction<StudentData>) => {
      state.currentStudent = action.payload;
      state.loading = false;
      state.error = null;
    },
    setStudentError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearStudent: (state) => {
      state.currentStudent = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  setStudentLoading,
  setStudentSuccess,
  setStudentError,
  clearStudent,
} = studentSlice.actions;

export default studentSlice.reducer;

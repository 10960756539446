import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import moment from "moment";

// Interfaces
interface ExportFilters {
  class?: string;
  section?: string;
  name?: string;
  rollNo?: string;
  startDate?: string;
  endDate?: string;
}

interface TooltipButtonConfig {
  show: boolean;
  tooltip?: string;
  icon?: string;
  onClick?: () => void;
}

interface ExportOption {
  label: string;
  icon: string;
  onClick?: () => void;
}

interface ExportConfig {
  show: boolean;
  tooltip?: string;
  icon?: string;
  text?: string;
  options?: ExportOption[];
  useExportModal?: boolean;
  modalTitle?: string;
  exportEndpoint?: string;
  onExport?: (filters: ExportFilters) => Promise<void>;
}

interface AddButtonConfig {
  show: boolean;
  tooltip?: string;
  icon?: string;
  text?: string;
  onClick?: () => void;
}

interface ExportModalConfig {
  showClassSection?: boolean;
  showNameRoll?: boolean;
  showDateRange?: boolean;
  additionalFields?: Array<{
    name: string;
    label: string;
    type: string;
  }>;
}

interface TooltipOptionProps {
  showRefresh?: boolean;
  showPrint?: boolean;
  showExport?: boolean;
  showAddButton?: boolean;
  additionalButtons?: Array<{
    tooltip: string;
    icon: string;
    onClick: () => void;
  }>;
  refreshConfig?: Partial<TooltipButtonConfig>;
  printConfig?: Partial<TooltipButtonConfig>;
  exportConfig?: Partial<ExportConfig>;
  addButtonConfig?: Partial<AddButtonConfig>;
  onRefresh?: () => void;
  onPrint?: () => void;
  exportModalConfig?: Partial<ExportModalConfig>;
  className?: string;
  buttonClassName?: string;
}

// Default configurations
const defaultExportConfig: ExportConfig = {
  show: true,
  tooltip: "Export",
  icon: "ti ti-file-export",
  text: "Export",
  options: [
    {
      label: "Export as Excel",
      icon: "ti ti-file-type-xls",
    },
  ],
  useExportModal: false,
  onExport: async () => {},
};

const defaultRefreshConfig: TooltipButtonConfig = {
  show: true,
  tooltip: "Refresh",
  icon: "ti ti-refresh",
};

const defaultPrintConfig: TooltipButtonConfig = {
  show: false,
  tooltip: "Print",
  icon: "ti ti-printer",
};

const defaultAddButtonConfig: AddButtonConfig = {
  show: false,
  tooltip: "Add New",
  icon: "ti ti-plus",
  text: "Add New",
};

const defaultModalConfig: ExportModalConfig = {
  showClassSection: true,
  showNameRoll: true,
  showDateRange: true,
  additionalFields: [],
};

const TooltipOption: React.FC<TooltipOptionProps> = ({
  showRefresh = true,
  showPrint = false,
  showExport = true,
  showAddButton = false,
  additionalButtons = [],
  refreshConfig = defaultRefreshConfig,
  printConfig = defaultPrintConfig,
  exportConfig = defaultExportConfig,
  addButtonConfig = defaultAddButtonConfig,
  onRefresh,
  onPrint,
  exportModalConfig = defaultModalConfig,
  className = "",
  buttonClassName = "btn btn-outline-light bg-white btn-icon",
}) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [filters, setFilters] = useState<ExportFilters>({});
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  const handleDateCallback = (start: moment.Moment, end: moment.Moment) => {
    setDateRange({ start, end });
    setFilters((prev) => ({
      ...prev,
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD"),
    }));
  };

  const handleExport = async () => {
    if (exportConfig.useExportModal) {
      setShowExportModal(true);
    } else if (exportConfig.onExport) {
      try {
        setExporting(true);
        await exportConfig.onExport(filters);
        setShowExportModal(false);
      } catch (error) {
        console.error("Export failed:", error);
      } finally {
        setExporting(false);
      }
    }
  };

  const handleExportSubmit = async () => {
    if (exportConfig.onExport) {
      try {
        setExporting(true);
        await exportConfig.onExport(filters);
        // Close modal after successful export
        const modalEl = document.getElementById("export_modal");
        if (modalEl) {
          const modal = modalEl.querySelector(
            '[data-bs-dismiss="modal"]'
          ) as HTMLElement;
          modal?.click();
        }
      } catch (error) {
        console.error("Export failed:", error);
      } finally {
        setExporting(false);
      }
    }
  };

  return (
    <>
      <div className={`d-flex align-items-center ${className}`}>
        {/* Refresh Button */}
        {showRefresh && refreshConfig.show && (
          <div className="pe-1 mb-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-refresh">{refreshConfig.tooltip}</Tooltip>
              }
            >
              <button
                className={buttonClassName}
                onClick={refreshConfig.onClick || onRefresh}
              >
                <i className={refreshConfig.icon} />
              </button>
            </OverlayTrigger>
          </div>
        )}

        {/* Print Button */}
        {showPrint && printConfig.show && (
          <div className="pe-1 mb-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-print">{printConfig.tooltip}</Tooltip>
              }
            >
              <button
                type="button"
                className={buttonClassName}
                onClick={printConfig.onClick || onPrint}
              >
                <i className={printConfig.icon} />
              </button>
            </OverlayTrigger>
          </div>
        )}

        {/* Additional Custom Buttons */}
        {additionalButtons.map((button, index) => (
          <div key={index} className="pe-1 mb-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-custom-${index}`}>
                  {button.tooltip}
                </Tooltip>
              }
            >
              <button
                type="button"
                className={buttonClassName}
                onClick={button.onClick}
              >
                <i className={button.icon} />
              </button>
            </OverlayTrigger>
          </div>
        ))}

        {/* Export Dropdown */}
        {showExport && exportConfig.show && (
          <div className="dropdown me-2 mb-2">
            <Link
              to="#"
              className="dropdown-toggle btn btn-light fw-medium d-inline-flex align-items-center"
              data-bs-toggle="dropdown"
            >
              <i className={`${exportConfig.icon} me-2`} />
              {exportConfig.text}
            </Link>
            <ul className="dropdown-menu dropdown-menu-end p-3">
              {exportConfig.options?.map((option, index) => (
                <li key={index}>
                  <Link
                    to="#"
                    className="dropdown-item rounded-1"
                    data-bs-toggle="modal"
                    data-bs-target="#export_modal"
                    onClick={() => {
                      if (option.onClick) {
                        option.onClick();
                      }
                    }}
                  >
                    <i className={`${option.icon} me-1`} />
                    {option.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Add Button */}
        {showAddButton && addButtonConfig.show && (
          <div className="mb-2">
            <Link
              to="#"
              className="btn btn-primary"
              onClick={addButtonConfig.onClick}
            >
              <i className={`${addButtonConfig.icon} me-2`} />
              {addButtonConfig.text}
            </Link>
          </div>
        )}
      </div>

      {/* Export Modal */}
      <div className="modal fade" id="export_modal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="modal-title">
                    {exportConfig.modalTitle || "Export Fees Data"}
                  </h4>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowExportModal(false)}
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>

            <div className="modal-body p-4">
              <div className="row g-3">
                {/* Student Info Section */}
                <div className="col-12">
                  <div className="card border shadow-none mb-4">
                    <div className="card-header bg-transparent">
                      <h5 className="card-title mb-0">
                        <i className="ti ti-user me-2"></i>
                        Student Filters
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        {exportModalConfig.showNameRoll && (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="text-muted small mb-1">
                                  Student Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Filter by name..."
                                  value={filters.name || ""}
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      name: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="text-muted small mb-1">
                                  Roll Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Filter by roll number..."
                                  value={filters.rollNo || ""}
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      rollNo: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {exportModalConfig.showClassSection && (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="text-muted small mb-1">
                                  Class
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Filter by class..."
                                  value={filters.class || ""}
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      class: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="text-muted small mb-1">
                                  Section
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Filter by section..."
                                  value={filters.section || ""}
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      section: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Date Range Section */}
                {exportModalConfig.showDateRange && (
                  <div className="col-12">
                    <div className="card border shadow-none">
                      <div className="card-header bg-transparent">
                        <h5 className="card-title mb-0">
                          <i className="ti ti-calendar me-2"></i>
                          Date Range
                        </h5>
                      </div>
                      <div className="card-body">
                        <DateRangePicker
                          initialSettings={{
                            startDate: dateRange.start.toDate(),
                            endDate: dateRange.end.toDate(),
                            ranges: {
                              Today: [moment().toDate(), moment().toDate()],
                              Yesterday: [
                                moment().subtract(1, "days").toDate(),
                                moment().subtract(1, "days").toDate(),
                              ],
                              "Last 7 Days": [
                                moment().subtract(6, "days").toDate(),
                                moment().toDate(),
                              ],
                              "Last 30 Days": [
                                moment().subtract(29, "days").toDate(),
                                moment().toDate(),
                              ],
                              "This Month": [
                                moment().startOf("month").toDate(),
                                moment().endOf("month").toDate(),
                              ],
                              "Last Month": [
                                moment()
                                  .subtract(1, "month")
                                  .startOf("month")
                                  .toDate(),
                                moment()
                                  .subtract(1, "month")
                                  .endOf("month")
                                  .toDate(),
                              ],
                            },
                          }}
                          onCallback={handleDateCallback}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={`${dateRange.start.format(
                              "D MMM, YYYY"
                            )} - ${dateRange.end.format("D MMM, YYYY")}`}
                            readOnly
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                )}

                {/* Additional Fields Section */}
                {exportModalConfig.additionalFields &&
                  exportModalConfig.additionalFields.length > 0 && (
                    <div className="col-12">
                      <div className="card border shadow-none">
                        <div className="card-header bg-transparent">
                          <h5 className="card-title mb-0">
                            <i className="ti ti-adjustments me-2"></i>
                            Additional Filters
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row g-3">
                            {exportModalConfig.additionalFields.map(
                              (field, index) => (
                                <div key={index} className="col-md-6">
                                  <div className="form-group">
                                    <label className="text-muted small mb-1">
                                      {field.label}
                                    </label>
                                    <input
                                      type={field.type}
                                      className="form-control"
                                      onChange={(e) =>
                                        setFilters((prev) => ({
                                          ...prev,
                                          [field.name]: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <div className="modal-footer border-top">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setShowExportModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleExportSubmit}
                disabled={exporting}
              >
                <i
                  className={`ti ti-${
                    exporting ? "loader" : "file-export"
                  } me-2`}
                ></i>
                {exporting ? "Exporting..." : "Export"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TooltipOption;

// FeeParticularsDisplay.tsx - Create this as a new component
import React, { useEffect, useRef, useState } from "react";

interface FeeParticular {
  description: string;
  amount: number;
  formattedAmount: string;
  notes: string;
  termDetails: Array<{
    term: string;
    academicYear: string;
    isPending: boolean;
    _id: string;
  }>;
}

interface FeeParticularsDisplayProps {
  particulars: FeeParticular[];
}

const FeeParticularsDisplay: React.FC<FeeParticularsDisplayProps> = ({
  particulars,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleCount, setVisibleCount] = useState<number>(particulars.length);

  useEffect(() => {
    const calculateVisibleItems = () => {
      const container = containerRef.current;
      if (!container) return;

      container.style.display = "block";

      const items = container.getElementsByClassName("fee-chip");
      const containerHeight = 64; // Height for 2 rows
      let totalHeight = 0;
      let visibleItems = 0;

      for (let i = 0; i < items.length; i++) {
        const item = items[i] as HTMLElement;
        totalHeight += item.offsetHeight + 4;

        if (totalHeight > containerHeight) {
          break;
        }
        visibleItems = i + 1;
      }

      container.style.display = "flex";
      setVisibleCount(visibleItems);
    };

    calculateVisibleItems();
    window.addEventListener("resize", calculateVisibleItems);
    return () => window.removeEventListener("resize", calculateVisibleItems);
  }, [particulars]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "300px",
        display: "flex",
        flexWrap: "wrap",
        gap: "4px",
        maxHeight: "64px",
        overflow: "hidden",
      }}
    >
      {particulars.slice(0, visibleCount).map((item, index) => (
        <span
          key={index}
          className="badge bg-light-300 text-dark fee-chip"
          style={{
            padding: "4px 8px",
            fontSize: "0.85rem",
            height: "28px",
            display: "inline-flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          {item.description}
        </span>
      ))}
      {visibleCount < particulars.length && (
        <span
          className="badge bg-light-300 text-dark fee-chip"
          style={{
            padding: "4px 8px",
            fontSize: "0.85rem",
            height: "28px",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          +{particulars.length - visibleCount} more
        </span>
      )}
    </div>
  );
};

export default FeeParticularsDisplay;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import {
  gender,
  route,
} from "../../../../core/common/selectoption/selectoption";
import { TagsInput } from "react-tag-input-component";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { useLocation } from "react-router-dom";
import { useApi } from "../../../../utils/api";
import StudentModals from "../studentModals";

interface Sibling {
  name: string;
  class: string;
  section: string;
  relation: string;
}

const AddStudent = () => {
  const routes = all_routes;

  const navigate = useNavigate();

  const [academicYears, setAcademicYears] = useState<any[]>([]);
  const [classes, setClasses] = useState<any[]>([]);
  const [sections, setSections] = useState<any[]>([]);

  const { AxiosGetWithToken, AxiosPostWithToken, AxiosPutWithToken } = useApi();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>(["English", "Spanish"]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const location = useLocation();

  // const addNewContent = () => {
  //   setNewContents([...newContents, newContents.length]);
  // };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };

  const [fetchedStudentId, setFetchedStudentId] = useState("");

  const [selectedAcademicYearId, setSelectedAcademicYearId] = useState<
    null | string
  >(null);
  const [admissionNo, setAdmissionNo] = useState("");
  const [admissionDate, setAdmissionDate] = useState<null | string>(null);
  const [emisNo, setEmisNo] = useState("");
  const [selectedClassId, setSelectedClassId] = useState<null | string>(null);
  const [selectedSectionId, setSelectedSectionId] = useState<null | string>(
    null
  );
  const [rollNo, setRollNo] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const [nameTamil, setNameTamil] = useState("");
  const [selectedGender, setSelectedGender] = useState<null | string>(null);
  const [dateOfBirth, setDateOfBirth] = useState<null | string>(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [religion, setReligion] = useState("");
  const [community, setCommunity] = useState("");
  const [caste, setCaste] = useState("");
  const [motherTongue, setMotherTongue] = useState("");
  const [nationality, setNationality] = useState("");
  const [state, setState] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [fatherPhoneNumber, setFatherPhoneNumber] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherName, setMotherName] = useState("");
  const [motherPhoneNumber, setMotherPhoneNumber] = useState("");
  const [motherOccupation, setMotherOccupation] = useState("");
  const [hasGuardian, setHasGuardian] = useState(false);
  const [guardianName, setGuardianName] = useState("");
  const [guardianRelation, setGuardianRelation] = useState("");
  const [guardianPhoneNumber, setGuardianPhoneNumber] = useState("");
  const [guardianOccupation, setGuardianOccupation] = useState("");
  const [hasSiblings, setHasSiblings] = useState(false);
  const [siblingsData, setSiblingsData] = useState<Sibling[]>([
    { name: "", class: "", section: "", relation: "" },
  ]);
  const [address, setAddress] = useState("");
  const [allergies, setAllergies] = useState<string[]>([]);
  const [medications, setMedications] = useState<string[]>([]);
  const [identificationMarks, setIdentificationMarks] = useState("");
  const [previousSchoolName, setPreviousSchoolName] = useState("");
  const [previousSchoolPeriodOfStudy, setPreviousSchoolPeriodOfStudy] =
    useState("");
  const [previousSchoolClass, setPreviousSchoolClass] = useState("");

  const addNewContent = () => {
    setSiblingsData([
      ...siblingsData,
      { name: "", class: "", section: "", relation: "" },
    ]);
  };

  const handleInputChange = (
    index: number,
    field: keyof Sibling,
    value: string
  ) => {
    const newSiblings = [...siblingsData];
    newSiblings[index][field] = value;
    setSiblingsData(newSiblings);
  };

  useEffect(() => {
    if (location.pathname === routes.editStudent) {
      setIsEdit(true);

      const { studentId } = location.state || {};
      setFetchedStudentId(studentId);

      const fetchSutdent = async () => {
        try {
          // Fetch student
          const response = await AxiosGetWithToken(`/student/${studentId}`);
          const fetchedStudent = response.student;
          setSelectedAcademicYearId(fetchedStudent.academicYear._id);
          setAdmissionNo(fetchedStudent.admissionNo);
          setAdmissionDate(fetchedStudent.admissionDate);
          setEmisNo(fetchedStudent.emisNo);
          setSelectedClassId(fetchedStudent.class._id);
          setSelectedSectionId(fetchedStudent.section._id);
          setRollNo(fetchedStudent.rollNo);
          setNameEnglish(fetchedStudent.name.english);
          setNameTamil(fetchedStudent.name.tamil);
          setSelectedGender(fetchedStudent.gender);
          setDateOfBirth(fetchedStudent.dateOfBirth);
          setBloodGroup(fetchedStudent.bloodGroup);
          setReligion(fetchedStudent.religion);
          setCommunity(fetchedStudent.community);
          setCaste(fetchedStudent.caste);
          setMotherTongue(fetchedStudent.motherTongue);
          setNationality(fetchedStudent.nationality);
          setState(fetchedStudent.state);
          setFatherName(fetchedStudent.father.name);
          setFatherPhoneNumber(fetchedStudent.father.mobileNumber);
          setFatherOccupation(fetchedStudent.father.occupation);
          setMotherName(fetchedStudent.mother.name);
          setMotherPhoneNumber(fetchedStudent.mother.mobileNumber);
          setMotherOccupation(fetchedStudent.mother.occupation);
          setHasGuardian(fetchedStudent.hasGuardian);
          if (fetchedStudent.hasGuardian) {
            setGuardianName(fetchedStudent.guardian.name);
            setGuardianPhoneNumber(fetchedStudent.guardian.mobileNumber);
            setGuardianOccupation(fetchedStudent.guardian.occupation);
            setGuardianRelation(fetchedStudent.guardian.relation);
          }
          setHasSiblings(fetchedStudent.hasSiblings);
          setSiblingsData(fetchedStudent.siblings);
          setAddress(fetchedStudent.address);
          setAllergies(fetchedStudent.allergies);
          setMedications(fetchedStudent.medications);
          setIdentificationMarks(fetchedStudent.identificationMarks);
          setPreviousSchoolName(fetchedStudent.previousSchool.name);
          setPreviousSchoolPeriodOfStudy(
            fetchedStudent.previousSchool.periodOfStudy
          );
          setPreviousSchoolClass(fetchedStudent.previousSchool.class);
        } catch (error) {
          console.error("Failed to fetch student", error);
        }
      };
      fetchSutdent();
    } else {
      setIsEdit(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchAcademicYearsAndClasses = async () => {
      try {
        // Fetch academic years
        const academicYearsData = await AxiosGetWithToken("/academicYear");
        setAcademicYears(academicYearsData);

        // Fetch classes (with sections included)
        const classesData = await AxiosGetWithToken("/class");
        setClasses(classesData);
      } catch (error) {
        console.error("Failed to fetch academic years or classes", error);
      }
    };

    fetchAcademicYearsAndClasses();
  }, []); // Empty dependency array ensures this runs only on mount

  const handleAcademicYearChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      setSelectedAcademicYearId(selectedOption.value);
    } else {
      setSelectedAcademicYearId(null);
    }
  };

  const handleClassChange = (selectedClass: Option | null) => {
    if (selectedClass) {
      setSelectedClassId(selectedClass.value);
      const selectedClassData = classes.find(
        (cls) => cls._id === selectedClass.value
      );
      setSections(selectedClassData.sections); // Set sections directly from the selected class
    } else {
      setSelectedClassId(null);
    }
  };

  const handleSectionChange = (selectedSection: Option | null) => {
    if (selectedSection) {
      setSelectedSectionId(selectedSection.value);
    } else {
      setSelectedSectionId(null);
    }
  };

  const handleGenderChange = (selectedGender: Option | null) => {
    if (selectedGender) {
      setSelectedGender(selectedGender.value);
    } else {
      setSelectedGender(null);
    }
  };

  const handleAddStudentClick = async () => {
    // console.log("Academic year:", selectedAcademicYearId);
    // console.log("Admission Number:", admissionNo);
    // console.log("Admission Date:", admissionDate);
    // console.log("EMIS Number:", emisNo);
    // console.log("Roll Number:", rollNo);
    // console.log("Class:", selectedClassId);
    // console.log("Section:", selectedSectionId);
    // console.log("Name in English:", nameEnglish);
    // console.log("Name in Tamil:", nameTamil);
    // console.log("Gender:", selectedGender);
    // console.log("Date of Birth:", dateOfBirth);
    // console.log("Blood Group:", bloodGroup);
    // console.log("Religion:", religion);
    // console.log("Community:", community);
    // console.log("Caste:", caste);
    // console.log("Mother Tongue:", motherTongue);

    // console.log("Father Name:", fatherName);
    // console.log("Father Phone Number:", fatherPhoneNumber);
    // console.log("Father Occupation:", fatherOccupation);

    // console.log("Mother Name:", motherName);
    // console.log("Mother Phone Number:", motherPhoneNumber);
    // console.log("Mother Occupation:", motherOccupation);

    // console.log("Has Guardian:", hasGuardian);
    // console.log("Guardian Name:", guardianName);
    // console.log("Guardian Relation:", guardianRelation);
    // console.log("Guardian Phone Number:", guardianPhoneNumber);
    // console.log("Guardian Occupation:", guardianOccupation);

    // console.log("Has Sibling in Same School:", hasSiblingInSameSchool);
    // console.log("Siblings:", siblings)
    // console.log("Address:", address);
    // console.log("Allergies:", allergies);
    // console.log("Medications:", medications);
    // console.log("Identification Marks:", identificationMarks);
    // console.log("Previous School Name:", previousSchoolName);
    // console.log(
    //   "Previous School Period of Study:",
    //   previousSchoolPeriodOfStudy
    // );
    // console.log("Previous School Class:", previousSchoolClass);

    const siblings = hasSiblings ? siblingsData : undefined;

    const payload = {
      academicYear: selectedAcademicYearId, // ID of the selected academic year
      admissionNo, // Admission number
      admissionDate, // Admission date in ISO format
      emisNo, // EMIS number
      rollNo, // Roll number
      name: {
        english: nameEnglish,
        tamil: nameTamil || undefined,
      },
      gender: selectedGender, // Gender string
      dateOfBirth, // Date of birth in ISO format
      bloodGroup, // Blood group
      religion, // Religion
      community, // Community
      caste, // Caste
      motherTongue, // Mother tongue
      nationality,
      state,
      identificationMarks, // Identification marks
      allergies, // Array of allergies
      medications, // Array of medications
      father: {
        name: fatherName,
        mobileNumber: fatherPhoneNumber,
        occupation: fatherOccupation,
      },
      mother: {
        name: motherName,
        mobileNumber: motherPhoneNumber,
        occupation: motherOccupation,
      },
      hasGuardian, // Whether the student has a guardian
      guardian: hasGuardian
        ? {
            // If guardian exists, provide details
            name: guardianName,
            mobileNumber: guardianPhoneNumber,
            occupation: guardianOccupation,
            relation: guardianRelation,
          }
        : undefined, // Set `guardian` as `undefined` if there's no guardian
      hasSiblings,
      siblings,
      address, // Address of the student
      previousSchool:
        previousSchoolName || previousSchoolPeriodOfStudy || previousSchoolClass
          ? {
              name: previousSchoolName,
              periodOfStudy: previousSchoolPeriodOfStudy,
              class: previousSchoolClass,
            }
          : undefined, // Populate previous school if it exists
      class: selectedClassId, // ID of selected class
      section: selectedSectionId, // ID of selected section
      // Sibling section left out as requested
    };

    try {
      const route = "/student";
      const response = await AxiosPostWithToken(route, payload);
      console.log("Response:", response);
      navigate(routes.studentList);
    } catch (error) {
      console.error("Failed to create student:", error);
      // Handle error (show error message etc.)
    }
  };

  const handleUpdateStudentClick = async () => {
    const siblings = hasSiblings ? siblingsData : undefined;
    const payload = {
      academicYear: selectedAcademicYearId, // ID of the selected academic year
      admissionNo, // Admission number
      admissionDate, // Admission date in ISO format
      emisNo, // EMIS number
      rollNo, // Roll number
      name: {
        english: nameEnglish,
        tamil: nameTamil || undefined,
      },
      gender: selectedGender, // Gender string
      dateOfBirth, // Date of birth in ISO format
      bloodGroup, // Blood group
      religion, // Religion
      community, // Community
      caste, // Caste
      motherTongue, // Mother tongue
      nationality,
      state,
      identificationMarks, // Identification marks
      allergies, // Array of allergies
      medications, // Array of medications
      father: {
        name: fatherName,
        mobileNumber: fatherPhoneNumber,
        occupation: fatherOccupation,
      },
      mother: {
        name: motherName,
        mobileNumber: motherPhoneNumber,
        occupation: motherOccupation,
      },
      hasGuardian, // Whether the student has a guardian
      guardian: hasGuardian
        ? {
            // If guardian exists, provide details
            name: guardianName,
            mobileNumber: guardianPhoneNumber,
            occupation: guardianOccupation,
            relation: guardianRelation,
          }
        : undefined, // Set `guardian` as `undefined` if there's no guardian
      hasSiblings,
      siblings,
      address, // Address of the student
      previousSchool:
        previousSchoolName || previousSchoolPeriodOfStudy || previousSchoolClass
          ? {
              name: previousSchoolName,
              periodOfStudy: previousSchoolPeriodOfStudy,
              class: previousSchoolClass,
            }
          : undefined, // Populate previous school if it exists
      class: selectedClassId, // ID of selected class
      section: selectedSectionId, // ID of selected section
      // Sibling section left out as requested
    };

    try {
      const route = `/student/${fetchedStudentId}`;
      const response = await AxiosPutWithToken(route, payload);
      console.log("Response:", response);
      navigate(routes.studentDetail, {
        state: {
          studentId: fetchedStudentId,
        },
      });
    } catch (error) {
      console.error("Failed to create student:", error);
      // Handle error (show error message etc.)
    }
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Student</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  {/* <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li> */}
                  <li className="breadcrumb-item">
                    <Link to={routes.studentList}>Students</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {isEdit ? "Edit" : "Add"} Student
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form>
                {/* Personal Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-info-square-rounded fs-16" />
                      </span>
                      <h4 className="text-dark">Personal Information</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row row-cols-xxl-5 row-cols-md-6">
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Academic Year</label>
                          <CommonSelect
                            className="select"
                            options={academicYears.map((year: any) => ({
                              label: year.year, // Ensure `year.year` is the correct field
                              value: year._id, // Ensure `_id` or another unique identifier is correct
                            }))}
                            onChange={handleAcademicYearChange}
                            value={isEdit ? selectedAcademicYearId! : undefined}
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={admissionNo}
                            onChange={(input) =>
                              setAdmissionNo(input.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Date</label>
                          <div className="input-icon position-relative">
                            <DatePicker
                              className="form-control datetimepicker"
                              format={{
                                format: "DD-MM-YYYY",
                                type: "mask",
                              }}
                              value={
                                admissionDate ? dayjs(admissionDate) : null
                              }
                              onChange={(date) => {
                                console.log("date object:", date); // Log the date object to see what it is
                                if (date && dayjs.isDayjs(date)) {
                                  const isoDate = date.toDate().toISOString();
                                  setAdmissionDate(isoDate);
                                  console.log("Date stored in state:", isoDate);
                                } else {
                                  setAdmissionDate(null);
                                  console.log("No date selected");
                                }
                              }}
                              placeholder="Select Date"
                            />

                            <span className="input-icon-addon">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">EMIS Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={emisNo}
                            onChange={(input) => setEmisNo(input.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Roll Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={rollNo}
                            onChange={(input) => setRollNo(input.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Class</label>
                          <CommonSelect
                            className="select"
                            options={classes.map((cls: any) => ({
                              label: cls.name,
                              value: cls._id,
                            }))}
                            onChange={handleClassChange}
                            value={isEdit ? selectedClassId! : undefined}
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Section</label>
                          <CommonSelect
                            className="select"
                            options={sections.map((sec: any) => ({
                              label: sec.name,
                              value: sec._id,
                            }))}
                            onChange={handleSectionChange}
                            value={isEdit ? selectedSectionId! : undefined}
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Name in English</label>
                          <input
                            type="text"
                            className="form-control"
                            value={nameEnglish}
                            onChange={(input) =>
                              setNameEnglish(input.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Name in தமிழ்</label>
                          <input
                            type="text"
                            className="form-control"
                            value={nameTamil}
                            onChange={(input) =>
                              setNameTamil(input.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Gender</label>
                          <CommonSelect
                            className="select"
                            options={gender}
                            onChange={handleGenderChange}
                            value={isEdit ? selectedGender! : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Date of Birth</label>
                          <div className="input-icon position-relative">
                            <DatePicker
                              className="form-control datetimepicker"
                              format={{
                                format: "DD-MM-YYYY",
                                type: "mask",
                              }}
                              value={dateOfBirth ? dayjs(dateOfBirth) : null}
                              onChange={(date) => {
                                console.log("date object:", date); // Log the date object to see what it is
                                if (date && dayjs.isDayjs(date)) {
                                  const isoDate = date.toDate().toISOString();
                                  setDateOfBirth(isoDate);
                                  console.log("Date stored in state:", isoDate);
                                } else {
                                  setDateOfBirth(null);
                                  console.log("No date selected");
                                }
                              }}
                              placeholder="Select Date"
                            />
                            <span className="input-icon-addon">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Blood Group</label>
                          <input
                            type="text"
                            className="form-control"
                            value={bloodGroup}
                            onChange={(input) =>
                              setBloodGroup(input.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Religion</label>
                          <input
                            type="text"
                            className="form-control"
                            value={religion}
                            onChange={(input) =>
                              setReligion(input.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Community</label>
                          <input
                            type="text"
                            className="form-control"
                            value={community}
                            onChange={(input) =>
                              setCommunity(input.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Caste</label>
                          <input
                            type="text"
                            className="form-control"
                            value={caste}
                            onChange={(input) => setCaste(input.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Mother Tongue</label>
                          <input
                            type="text"
                            className="form-control"
                            value={motherTongue}
                            onChange={(input) =>
                              setMotherTongue(input.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Nationality</label>
                          <input
                            type="text"
                            className="form-control"
                            value={nationality}
                            onChange={(input) =>
                              setNationality(input.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">State</label>
                          <input
                            type="text"
                            className="form-control"
                            value={state}
                            onChange={(input) => setState(input.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Personal Information */}
                {/* Parents & Guardian Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-user-shield fs-16" />
                      </span>
                      <h4 className="text-dark">
                        Parents &amp; Guardian Information
                      </h4>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Father’s Info</h5>
                      <div className="row">
                        <div className="col-md-12"></div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Father Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={fatherName}
                              onChange={(input) =>
                                setFatherName(input.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              value={fatherPhoneNumber}
                              onChange={(input) =>
                                setFatherPhoneNumber(input.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Father Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={fatherOccupation}
                              onChange={(input) =>
                                setFatherOccupation(input.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Mother’s Info</h5>
                      <div className="row">
                        <div className="col-md-12"></div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mother Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={motherName}
                              onChange={(input) =>
                                setMotherName(input.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              value={motherPhoneNumber}
                              onChange={(input) =>
                                setMotherPhoneNumber(input.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={motherOccupation}
                              onChange={(input) =>
                                setMotherOccupation(input.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-3">Guardian Details</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-2">
                            <div className="d-flex align-items-center flex-wrap">
                              <label className="form-label text-dark fw-normal me-2">
                                If Guardian Is
                              </label>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="parents"
                                  checked={hasGuardian ? false : true}
                                  onChange={(input) => {
                                    setHasGuardian(
                                      input.target.checked ? false : true
                                    );
                                    if (input.target.checked) {
                                      setGuardianName("");
                                      setGuardianPhoneNumber("");
                                      setGuardianOccupation("");
                                      setGuardianRelation("");
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="parents"
                                >
                                  Parents
                                </label>
                              </div>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="guardian"
                                  checked={hasGuardian}
                                  onChange={(input) =>
                                    setHasGuardian(input.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="guardian"
                                >
                                  Guardian
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {hasGuardian ? (
                          <>
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Guardian Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={guardianName}
                                  onChange={(input) =>
                                    setGuardianName(input.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Guardian Relation
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={guardianRelation}
                                  onChange={(input) =>
                                    setGuardianRelation(input.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={guardianPhoneNumber}
                                  onChange={(input) =>
                                    setGuardianPhoneNumber(input.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label">Occupation</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={guardianOccupation}
                                  onChange={(input) =>
                                    setGuardianOccupation(input.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Parents & Guardian Information */}
                {/* Sibilings */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-users fs-16" />
                      </span>
                      <h4 className="text-dark">Sibilings</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="addsibling-info">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-2">
                            <label className="form-label">Sibling Info</label>
                            <div className="d-flex align-items-center flex-wrap">
                              <label className="form-label text-dark fw-normal me-2">
                                Is Sibling studying in the same school
                              </label>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sibling"
                                  id="yes"
                                  checked={hasSiblings === true ? true : false}
                                  onChange={(input) =>
                                    setHasSiblings(input.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sibling"
                                  id="no"
                                  checked={hasSiblings === true ? false : true}
                                  onChange={(input) => {
                                    setHasSiblings(
                                      input.target.checked ? false : true
                                    );
                                    setSiblingsData([
                                      {
                                        name: "",
                                        class: "",
                                        section: "",
                                        relation: "",
                                      },
                                    ]);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {hasSiblings ? (
                          <>
                            {siblingsData.map((sibling, index) => (
                              <div key={index} className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sibling.name}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "name",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Class
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sibling.class}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "class",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Section
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sibling.section}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "section",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Relation
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sibling.relation}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "relation",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="border-top pt-3">
                              <Link
                                to="#"
                                onClick={addNewContent}
                                className="add-sibling btn btn-primary d-inline-flex align-items-center"
                              >
                                <i className="ti ti-circle-plus me-2" />
                                Add New
                              </Link>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Sibilings */}
                {/* Address */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-map fs-16" />
                      </span>
                      <h4 className="text-dark">Address</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Current Address</label>
                          <textarea
                            className="form-control"
                            rows={4}
                            value={address}
                            onChange={(input) => setAddress(input.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Address */}
                {/* Medical History */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-medical-cross fs-16" />
                      </span>
                      <h4 className="text-dark">
                        Medical History & Identification Marks
                      </h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="mb-3">
                        <label className="form-label">Allergies</label>
                        <TagsInput
                          // className="input-tags form-control"
                          value={allergies}
                          onChange={setAllergies}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Medications</label>
                        <TagsInput
                          // className="input-tags form-control"
                          value={medications}
                          onChange={setMedications}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Identification Marks
                        </label>
                        <textarea
                          className="form-control"
                          rows={4}
                          value={identificationMarks}
                          onChange={(input) =>
                            setIdentificationMarks(input.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Medical History */}
                {/* Previous School details */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building fs-16" />
                      </span>
                      <h4 className="text-dark">Previous School Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">School Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={previousSchoolName}
                            onChange={(input) =>
                              setPreviousSchoolName(input.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">Period of Study</label>
                          <input
                            type="text"
                            className="form-control"
                            value={previousSchoolPeriodOfStudy}
                            onChange={(input) =>
                              setPreviousSchoolPeriodOfStudy(input.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">Class</label>
                          <input
                            type="text"
                            className="form-control"
                            value={previousSchoolClass}
                            onChange={(input) =>
                              setPreviousSchoolClass(input.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Previous School details */}

                <div className="text-end">
                  <Link
                    to={isEdit ? routes.studentDetail : routes.studentList}
                    className="btn btn-light me-3"
                    state={{ studentId: isEdit ? fetchedStudentId : null }}
                  >
                    Cancel
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      isEdit ? handleUpdateStudentClick : handleAddStudentClick
                    }
                  >
                    {isEdit ? "Save" : "Add Student"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}

      <StudentModals />
    </>
  );
};

export default AddStudent;

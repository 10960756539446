import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../studentModals";
import StudentSidebar from "./studentSidebar";
import StudentBreadcrumb from "./studentBreadcrumb";
import { useApi } from "../../../../utils/api";
import { StudentData } from "../../../../core/data/interface";
import FeeParticularsDisplay from "./component/feeParticularDisplay";
import ViewFeeModal from "../ViewFee";

interface FeeParticular {
  description: string;
  amount: number;
  formattedAmount: string;
  notes: string;
  termDetails: Array<{
    term: string;
    academicYear: string;
    isPending: boolean;
    _id: string;
  }>;
}

interface FeeReceipt {
  _id: string;
  serialNumber: string;
  date: string;
  particulars: FeeParticular[];
  totalAmount: number;
  formattedAmount: string;
  payment: {
    mode: string;
    details: string;
  };
  status: string;
}

interface FeeSummary {
  totalAmountPaid: string;
  totalReceipts: number;
  lastPaidDate: string;
  paymentModes: string[];
  feeTypes: string[];
}

interface PaginationData {
  total: number;
  page: number;
  totalPages: number;
  limit: number;
}

const StudentFees = () => {
  const routes = all_routes;
  const { AxiosGetWithToken } = useApi();
  const student = useSelector(
    (state: any) => state.studentSlice.currentStudent
  );

  const [fees, setFees] = useState<FeeReceipt[]>([]);
  const [summary, setSummary] = useState<FeeSummary | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    page: 1,
    totalPages: 1,
    limit: 10,
  });
  const [selectedFeeId, setSelectedFeeId] = useState<string | null>(null);

  useEffect(() => {
    if (student?.rollNo) {
      fetchFees(1);
    }
  }, [student?.rollNo]);

  const fetchFees = async (page: number) => {
    try {
      setIsLoading(true);
      const response = await AxiosGetWithToken(
        `/fees/student/${student.rollNo}?page=${page}&limit=${pagination.limit}`
      );

      if (response) {
        setFees(response.fees);
        setSummary(response.summary);
        setPagination(response.pagination);
      }
    } catch (error) {
      console.error("Failed to fetch fees:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    fetchFees(newPage);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <StudentBreadcrumb studentId={student?._id} />
        </div>
        <ViewFeeModal feeId={selectedFeeId} />

        <div className="row">
          <StudentSidebar student={student} />
          <div className="col-xxl-9 col-xl-8">
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                  <li>
                    <Link to={routes.studentDetail} className="nav-link">
                      <i className="ti ti-school me-2" />
                      Student Details
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.studentFees} className="nav-link active">
                      <i className="ti ti-report-money me-2" />
                      Fees
                    </Link>
                  </li>
                </ul>

                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h4 className="mb-0">Fees</h4>
                        {summary && (
                          <p className="text-muted mt-2 mb-0">
                            Total Amount Paid: {summary.totalAmountPaid}
                          </p>
                        )}
                      </div>
                      <div className="dropdown">
                        <Link
                          to=""
                          className="btn btn-outline-light bg-white dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          <i className="ti ti-calendar-due me-2" />
                          Year : 2024 / 2025
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link to="" className="dropdown-item">
                              Year : 2024 / 2025
                            </Link>
                          </li>
                          <li>
                            <Link to="" className="dropdown-item">
                              Year : 2023 / 2024
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="card-body p-0">
                    <div className="table-responsive">
                      {isLoading ? (
                        <div className="text-center p-4">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          <p className="mt-2">Loading fees data...</p>
                        </div>
                      ) : (
                        <table className="table datatable mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th>Serial No</th>
                              <th>Date</th>
                              <th>Fees type</th>
                              <th>Amount</th>
                              <th>Mode</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fees.map((fee) => (
                              <tr key={fee._id}>
                                <td>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#view_fee_details"
                                    onClick={() => setSelectedFeeId(fee._id)}
                                    className="text-primary"
                                  >
                                    {fee.serialNumber}
                                  </Link>
                                </td>
                                <td>{fee.date}</td>
                                <td>
                                  <FeeParticularsDisplay
                                    particulars={fee.particulars}
                                  />
                                </td>
                                <td>{fee.formattedAmount}</td>
                                <td>{fee.payment.mode}</td>
                                <td>
                                  <span
                                    className={`badge ${
                                      fee.status === "active"
                                        ? "badge-soft-success"
                                        : "badge-soft-error"
                                    } d-inline-flex align-items-center`}
                                  >
                                    <i className="ti ti-circle-filled fs-5 me-1" />
                                    {fee.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>

                    {!isLoading && pagination.totalPages > 1 && (
                      <div className="row mt-4">
                        <div className="col d-flex justify-content-end">
                          <ul className="pagination">
                            <li
                              className={`page-item ${
                                pagination.page === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(pagination.page - 1)
                                }
                                disabled={pagination.page === 1}
                              >
                                Previous
                              </button>
                            </li>
                            {[...Array(pagination.totalPages)].map(
                              (_, index) => (
                                <li
                                  key={index + 1}
                                  className={`page-item ${
                                    pagination.page === index + 1
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <button
                                    className="page-link"
                                    onClick={() => handlePageChange(index + 1)}
                                  >
                                    {index + 1}
                                  </button>
                                </li>
                              )
                            )}
                            <li
                              className={`page-item ${
                                pagination.page === pagination.totalPages
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(pagination.page + 1)
                                }
                                disabled={
                                  pagination.page === pagination.totalPages
                                }
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StudentModals />
    </div>
  );
};

export default StudentFees;

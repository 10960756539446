import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import Invoice from "./Invoice";
import { useApi } from "../../../utils/api";

interface CashierInfo {
  cashierId: string;
  name: string;
  _id: string;
}

interface StudentInfo {
  studentId: string;
  name: string;
  class: string;
  section: string;
  rollNo: string;
  mobileNo: string;
  month: string;
  _id: string;
}

interface FeeParticular {
  serialNo: number;
  description: string;
  amount: number;
  notes: string;
  _id: string;
  termDetails: any[];
}

interface PaymentInfo {
  mode: string;
  _id: string;
}

interface FeeEntry {
  _id: string;
  serialNumber: string;
  date: string;
  student: StudentInfo;
  particulars: FeeParticular[];
  totalAmount: number;
  totalInWords: string;
  cashier: CashierInfo;
  status: string;
  payment: PaymentInfo;
  createdAt: string;
  updatedAt: string;
}

interface FeeResponse {
  message: string;
  feeEntry: FeeEntry;
}

interface ViewFeeModalProps {
  feeId: string | null;
}

const ViewFeeModal: React.FC<ViewFeeModalProps> = ({ feeId }) => {
  const [activeTab, setActiveTab] = useState<"details" | "invoice">("details");
  const [feeData, setFeeData] = useState<FeeEntry | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [printSignal, setPrintSignal] = useState(0);
  const [downloadSignal, setDownloadSignal] = useState(0);

  const { AxiosGetWithToken } = useApi();

  useEffect(() => {
    if (feeId) {
      fetchFeeDetails();
    }
  }, [feeId]);

  const fetchFeeDetails = async () => {
    if (!feeId) return;

    setIsLoading(true);
    try {
      const response = await AxiosGetWithToken(`/fees/${feeId}`);
      if (response.feeEntry) {
        setFeeData(response.feeEntry);
      }
    } catch (error) {
      console.error("Failed to fetch fee details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = () => {
    setPrintSignal((prev) => prev + 1);
  };

  const handleDownload = () => {
    setDownloadSignal((prev) => prev + 1);
  };

  const resetModalState = () => {
    setActiveTab("details");
    setPrintSignal(0);
    setDownloadSignal(0);
  };

  // Add event listener for modal close
  useEffect(() => {
    const modal = document.getElementById("view_fee_details");

    const handleModalClose = () => {
      resetModalState();
    };

    if (modal) {
      modal.addEventListener("hidden.bs.modal", handleModalClose);

      return () => {
        modal.removeEventListener("hidden.bs.modal", handleModalClose);
      };
    }
  }, []);

  return (
    <div className="modal fade" id="view_fee_details">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="modal-title">Fee Receipt Details</h4>
                {feeData?.status && (
                  <span className="badge badge-soft-success">Active</span>
                )}
              </div>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>

          <div className="modal-body p-0">
            <div className="border-bottom mb-4">
              <ul className="nav nav-tabs nav-tabs-bottom">
                <li className="nav-item flex-fill">
                  <a
                    className={`nav-link text-center py-3 ${
                      activeTab === "details" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("details")}
                    role="button"
                  >
                    <i className="ti ti-list me-2"></i>
                    Details
                  </a>
                </li>
                <li className="nav-item flex-fill">
                  <a
                    className={`nav-link text-center py-3 ${
                      activeTab === "invoice" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("invoice")}
                    role="button"
                  >
                    <i className="ti ti-file-invoice me-2"></i>
                    Invoice
                  </a>
                </li>
              </ul>
            </div>

            {isLoading ? (
              <div className="text-center p-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="px-4 pb-4">
                {activeTab === "details" && feeData ? (
                  <>
                    <div className="row g-3">
                      <div className="col-md-3">
                        <div className="card border shadow-none">
                          <div className="card-body">
                            <div className="text-muted small mb-1">
                              Receipt No
                            </div>
                            <div className="h5 mb-0">
                              {feeData.serialNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card border shadow-none">
                          <div className="card-body">
                            <div className="text-muted small mb-1">Date</div>
                            <div className="h5 mb-0">
                              {dayjs(feeData.date).format("DD MMM, YYYY")}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card border shadow-none">
                          <div className="card-body">
                            <div className="text-muted small mb-1">Amount</div>
                            <div className="h5 mb-0">
                              ₹{feeData.totalAmount.toLocaleString("en-IN")}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card border shadow-none">
                          <div className="card-body">
                            <div className="text-muted small mb-1">
                              Payment Mode
                            </div>
                            <div className="h5 mb-0 text-capitalize">
                              {feeData.payment.mode}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card border shadow-none mb-4">
                      <div className="card-header bg-transparent">
                        <h5 className="card-title mb-0">
                          <i className="ti ti-user me-2"></i>
                          Student Information
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-sm-6">
                            <div className="text-muted small mb-1">Name</div>
                            <div className="fw-medium">
                              {feeData.student.name}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="text-muted small mb-1">
                              Roll Number
                            </div>
                            <div className="fw-medium">
                              {feeData.student.rollNo}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="text-muted small mb-1">Class</div>
                            <div className="fw-medium">
                              {feeData.student.class} -{" "}
                              {feeData.student.section}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="text-muted small mb-1">
                              Contact Number
                            </div>
                            <div className="fw-medium">
                              {feeData.student.mobileNo}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card border shadow-none">
                      <div className="card-header bg-transparent">
                        <h5 className="card-title mb-0">
                          <i className="ti ti-receipt me-2"></i>
                          Fee Particulars
                        </h5>
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead className="table-light">
                              <tr>
                                <th style={{ width: "80px" }}>S.No</th>
                                <th>Description</th>
                                <th>Notes</th>
                                <th
                                  className="text-end"
                                  style={{ width: "120px" }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {feeData.particulars.map((item) => (
                                <tr key={item._id}>
                                  <td>{item.serialNo}</td>
                                  <td>{item.description}</td>
                                  <td>{item.notes || "-"}</td>
                                  <td className="text-end">
                                    ₹{item.amount.toLocaleString("en-IN")}
                                  </td>
                                </tr>
                              ))}
                              <tr className="table-light fw-bold">
                                <td colSpan={3} className="text-end">
                                  Total Amount
                                </td>
                                <td className="text-end">
                                  ₹{feeData.totalAmount.toLocaleString("en-IN")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="p-3 border-top">
                          <div className="text-muted fst-italic ">
                            {feeData.totalInWords}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : activeTab === "invoice" && feeData ? (
                  <Invoice
                    studentName={feeData.student.name}
                    grade={feeData.student.class}
                    rollNo={feeData.student.rollNo}
                    month={feeData.student.month}
                    serialNumber={feeData.serialNumber}
                    items={feeData.particulars.map((p) => ({
                      id: p.serialNo,
                      name: p.description,
                      amount: p.amount,
                    }))}
                    totalInWords={feeData.totalInWords}
                    printSignal={printSignal}
                    downloadSignal={downloadSignal}
                  />
                ) : null}
              </div>
            )}

            {activeTab === "invoice" && !isLoading && (
              <div className="border-top p-3">
                <div className="d-flex justify-content-end gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleDownload}
                  >
                    <i className="ti ti-download me-2"></i>
                    Download
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePrint}
                  >
                    <i className="ti ti-printer me-2"></i>
                    Print
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFeeModal;

import React, { useEffect } from "react";
import dayjs from "dayjs";
import { pdf } from "@react-pdf/renderer";
import InvoicePDF from "./InvoicePdf";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";

export type FeeType =
  | "Application Fee"
  | "Tuition Fee"
  | "Term Fee"
  | "Uniform Fee"
  | "Books & Note Books Fee"
  | "Shoe / Socks / belt Fee"
  | "Miscellaneous"
  | "Van Fee"
  | "Fine"
  | "Others";

const FEE_TYPES: FeeType[] = [
  "Application Fee",
  "Tuition Fee",
  "Term Fee",
  "Uniform Fee",
  "Books & Note Books Fee",
  "Shoe / Socks / belt Fee",
  "Miscellaneous",
  "Van Fee",
  "Fine",
  "Others",
];

interface InvoiceProps {
  studentName: string;
  grade: string;
  rollNo: string;
  month: string;
  serialNumber?: string;
  items: Array<{
    id: number;
    name: string;
    amount: number;
  }>;
  totalInWords: string;
  printSignal?: number;
  downloadSignal?: number;
}

const Invoice: React.FC<InvoiceProps> = ({
  studentName,
  grade,
  rollNo,
  month,
  serialNumber = "",
  items,
  totalInWords,
  printSignal,
  downloadSignal,
}) => {
  const itemsMap = new Map(items.map((item) => [item.name, item]));
  const total = items.reduce((sum, item) => sum + item.amount, 0);
  const currentDate = dayjs().format("DD-MM-YYYY");

  useEffect(() => {
    const handlePrint = async () => {
      try {
        // Generate PDF blob
        const blob = await pdf(
          <InvoicePDF
            studentName={studentName}
            grade={grade}
            rollNo={rollNo}
            month={month}
            serialNumber={serialNumber}
            items={items}
            totalInWords={totalInWords}
          />
        ).toBlob();

        // Open in new window
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url);

        if (printWindow) {
          // Clean up the URL after the window is loaded
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(url);
          };
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    };

    if (printSignal && printSignal > 0) {
      handlePrint();
    }
  }, [
    printSignal,
    studentName,
    grade,
    rollNo,
    month,
    serialNumber,
    items,
    totalInWords,
  ]);

  useEffect(() => {
    const handleDownload = async () => {
      try {
        // Generate PDF blob
        const blob = await pdf(
          <InvoicePDF
            studentName={studentName}
            grade={grade}
            rollNo={rollNo}
            month={month}
            serialNumber={serialNumber}
            items={items}
            totalInWords={totalInWords}
          />
        ).toBlob();

        // Create download link
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `invoice-${studentName}-${month}.pdf`; // Custom filename
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    };

    if (downloadSignal) {
      handleDownload();
    }
  }, [
    downloadSignal,
    studentName,
    grade,
    rollNo,
    month,
    serialNumber,
    items,
    totalInWords,
  ]);

  return (
    <div
      style={{ height: "calc(100vh - 300px)", overflow: "auto", padding: 30 }}
    >
      {/* Header */}
      <div style={{ alignItems: "center", marginBottom: 10 }}>
        <div
          style={{
            width: 60,
            height: 60,
            backgroundColor: "#6B21A8",
            borderRadius: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <ImageWithBasePath
            src="assets/img/icons/lions-img.jpg"
            className="img-fluid"
            alt="Logo"
            width={100}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <h2
            style={{
              fontSize: 24,
              color: "#6B21A8",
              fontWeight: "bold",
              marginBottom: 8,
            }}
          >
            LIONS INTERNATIONAL SCHOOL
          </h2>
          <p style={{ fontSize: 10, color: "#4B5563", marginBottom: 4 }}>
            Arakulam, Karadivavi (Po), Palladam (Tk), Tirupur - 641 658
          </p>
          <p style={{ fontSize: 10, color: "#4B5563" }}>
            Contact No: 04255 - 265555 / 63850 49497 / 63850 84449
          </p>
        </div>
      </div>

      {/* Bill Header */}
      <div
        style={{
          display: "flex",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            padding: 8,
            backgroundColor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: 8,
          }}
        >
          <span
            style={{
              fontSize: 10,
              color: "#374151",
              fontWeight: "bold",
              marginBottom: 4,
              display: "block",
            }}
          >
            Serial No:
          </span>
          <span style={{ fontSize: 12, color: "#1F2937" }}>
            {serialNumber || "-"}
          </span>
        </div>
        <div
          style={{
            flex: 1,
            padding: 8,
            backgroundColor: "#6B21A8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 8,
          }}
        >
          <span style={{ fontSize: 18, color: "white", fontWeight: "bold" }}>
            Cash Bill
          </span>
        </div>
        <div
          style={{
            flex: 1,
            padding: 8,
            backgroundColor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: 8,
          }}
        >
          <span
            style={{
              fontSize: 10,
              color: "#374151",
              fontWeight: "bold",
              marginBottom: 4,
              display: "block",
            }}
          >
            Date:
          </span>
          <span style={{ fontSize: 12, color: "#1F2937" }}>{currentDate}</span>
        </div>
      </div>

      {/* Student Details */}
      <div
        style={{
          display: "flex",
          marginBottom: 10,
          border: "1px solid #E5E7EB",
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        {[
          { label: "Name", value: studentName, width: "40%" },
          { label: "Grade", value: grade, width: "20%" },
          { label: "Roll No", value: rollNo, width: "20%" },
          { label: "Month", value: month, width: "20%" },
        ].map((item, index) => (
          <div
            key={index}
            style={{
              width: item.width,
              padding: 8,
              backgroundColor: "#F9FAFB",
              borderRight: index < 3 ? "1px solid #E5E7EB" : "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: 10,
                color: "#374151",
                fontWeight: "bold",
                marginBottom: 4,
              }}
            >
              {item.label}
            </span>
            <span style={{ fontSize: 12, color: "#1F2937" }}>{item.value}</span>
          </div>
        ))}
      </div>

      {/* Fee Table */}
      <div
        style={{
          marginBottom: 10,
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        {/* Table Header */}
        <div
          style={{
            display: "flex",
            backgroundColor: "#DC2626",
            minHeight: 30,
            borderLeft: "1px solid #E5E7EB",
            borderRight: "1px solid #E5E7EB",
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <div
            style={{
              width: "10%",
              borderRight: "1px solid #E5E7EB",
              padding: 8,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>
              S.No
            </span>
          </div>

          <div
            style={{
              width: "60%",
              borderRight: "1px solid #E5E7EB",
              padding: 8,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>
              Particulars
            </span>
          </div>

          <div
            style={{
              width: "30%",
              padding: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>
              Amount
            </span>
          </div>
        </div>

        {/* Table Body */}
        {FEE_TYPES.map((feeType, index) => {
          const item = itemsMap.get(feeType);
          return (
            <div
              key={feeType}
              style={{
                display: "flex",
                minHeight: 30,
                borderLeft: "1px solid #E5E7EB",
                borderRight: "1px solid #E5E7EB",
                borderBottom: "1px solid #E5E7EB",
              }}
            >
              <div
                style={{
                  width: "10%",
                  borderRight: "1px solid #E5E7EB",
                  padding: 8,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: 12 }}>{index + 1}</span>
              </div>

              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid #E5E7EB",
                  padding: 8,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: 12 }}>{feeType}</span>
              </div>

              <div
                style={{
                  width: "30%",
                  padding: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span style={{ fontSize: 12 }}>
                  {item ? `Rs. ${item.amount.toLocaleString("en-IN")}` : "-"}
                </span>
              </div>
            </div>
          );
        })}

        {/* Table Footer */}
        <div
          style={{
            display: "flex",
            backgroundColor: "#F9FAFB",
            minHeight: 30,
            borderLeft: "1px solid #E5E7EB",
            borderRight: "1px solid #E5E7EB",
            borderBottom: "1px solid #E5E7EB",
          }}
        >
          <div
            style={{
              width: "70%",
              borderRight: "1px solid #E5E7EB",
              padding: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Total Rs.</span>
          </div>

          <div
            style={{
              width: "30%",
              padding: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ fontSize: 12, fontWeight: "bold" }}>
              Rs. {total.toLocaleString("en-IN")}
            </span>
          </div>
        </div>
      </div>

      {/* Amount in Words */}
      <div style={{ marginBottom: 10 }}>
        <span
          style={{
            fontSize: 10,
            color: "#374151",
            fontWeight: "bold",
            marginBottom: 4,
            display: "block",
          }}
        >
          In Words:
        </span>
        <div
          style={{
            padding: 8,
            backgroundColor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: 8,
          }}
        >
          <span style={{ fontSize: 12, color: "#1F2937" }}>{totalInWords}</span>
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <span style={{ fontSize: 10, color: "#4B5563" }}>
            E-mail: principallisp2023@gmail.com
          </span>
          <span style={{ fontSize: 10, color: "#4B5563" }}>
            E-mail: admin@lisp.org.in
          </span>
          <span style={{ fontSize: 10, color: "#4B5563" }}>
            Web: www.lisp.org.in
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              marginTop: 20,
              paddingTop: 10,
              borderTop: "1px solid #D1D5DB",
              width: 200,
              textAlign: "center",
            }}
          >
            <span
              style={{ fontSize: 12, color: "#1F2937", fontWeight: "bold" }}
            >
              Cashier / Incharge
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;

import { configureStore } from "@reduxjs/toolkit";
import themeSettingSlice from "./themeSettingSlice";
import sidebarSlice from "./sidebarSlice";
import studentSlice from "./studentSlice";

const store = configureStore({
  reducer: {
    themeSetting: themeSettingSlice,
    sidebarSlice: sidebarSlice,
    studentSlice: studentSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
